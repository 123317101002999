import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import "./Slider.css";
import { Carousel, Button } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import { getNotices } from "../../Redux/Actions/Index";


function Slider() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const notices = useSelector(state => state.allNotices);

    useEffect(() => {
        dispatch(getNotices());
    }, [dispatch]);

    const handleReadMore = (id) => {
        console.log(`Navigating to news with ID: ${id}`); // Verificar el ID de la noticia que se va a leer
        navigate(`/noticia/${id}`);
        };

    const truncateDescription = (description) => {
        const maxLength = 60; // Número máximo de caracteres para una línea
        if (description.length > maxLength) {
            return description.substring(0, maxLength) + '...';
        }
        return description;
    };


    return (
       <div className="carousel-container">
        <Carousel autoplay className="carousel">
            {notices.slice(-3).map((notice, index) => (  // Toma las 3 noticias más recientes (últimas en el array)
                <div key={index} className="carousel-item">
                    <img
                        className="d-block w-100"
                        src={notice.imageUrl}
                        alt={`Slide ${index + 1}`}
                    />
                    <div className="carousel-caption">
                            <h3>{notice.title}</h3>
                            <p>{truncateDescription(notice.description)}</p>
                            <Button type="primary" style={{ backgroundColor: '#333', borderColor: '#333' }} onClick={() => handleReadMore(notice.id)}>
                                Ver Más
                            </Button>
                    </div>
                </div>
            ))}
        </Carousel>
       </div>  
    );
}

export {Slider};