import { useState } from "react";
import { useDispatch } from "react-redux";
import { postWinningWork } from '../../Redux/Actions/Index';
import { PlusOutlined } from '@ant-design/icons';
import { Form, Input, Button, Upload, Select } from 'antd';
import "./AdminDashboard.css";

const { Option } = Select;

function FormularioCrearTrabajoGanador() {
    const dispatch = useDispatch();
    const [fileList, setFileList] = useState([]);
    const [inputTrabajo, setInputTrabajo] = useState({
        title: "",
        category: "",
        subcategory: "",
        description: ""
    });

    const categories = ["Poesía", "Cuento breve", "Fotografía", "Dibujo"];
    const subcategories = ["Adulto", "Adolescente", "Niños"];

    const handleInputChange = (e) => {
        setInputTrabajo({
            ...inputTrabajo,
            [e.target.name]: e.target.value
        });
    };

    const handleCategoryChange = (value) => {
        setInputTrabajo({
            ...inputTrabajo,
            category: value,
            subcategory: "" // Reset subcategory when category changes
        });
    };

    const handleSubcategoryChange = (value) => {
        setInputTrabajo({
            ...inputTrabajo,
            subcategory: value
        });
    };

    const handleFileChange = ({ fileList }) => {
        setFileList(fileList);
    };

    const handleSubmit = async () => {
        const formData = new FormData();
        formData.append('title', inputTrabajo.title);
        formData.append('category', inputTrabajo.category);
        formData.append('subcategory', inputTrabajo.subcategory);
        formData.append('description', inputTrabajo.description);

        if (fileList.length > 0) {
            formData.append('imageUrl', fileList[0].originFileObj);
        }

        try {
            await dispatch(postWinningWork(formData));
            setInputTrabajo({
                title: "",
                category: "",
                subcategory: "",
                description: ""
            });
            setFileList([]);
        } catch (error) {
            console.error("Error posting winning work:", error);
        }
    };

    return (
        <div className="form-ant">
            <Form
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
                style={{ maxWidth: 600 }}
                onFinish={handleSubmit}
            >
                <h1>Subir Trabajo Ganador</h1>

                <Form.Item label="Título">
                    <Input
                        onChange={handleInputChange}
                        name="title"
                        placeholder="Escriba el título del trabajo ganador"
                        value={inputTrabajo.title}
                    />
                </Form.Item>

                <Form.Item label="Categoría">
                    <Select
                        placeholder="Seleccione una categoría"
                        onChange={handleCategoryChange}
                        value={inputTrabajo.category}
                    >
                        {categories.map((cat) => (
                            <Option key={cat} value={cat}>
                                {cat}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                {inputTrabajo.category && (
                    <Form.Item label="Subcategoría">
                        <Select
                            placeholder="Seleccione una subcategoría"
                            onChange={handleSubcategoryChange}
                            value={inputTrabajo.subcategory}
                        >
                            {subcategories.map((subcat) => (
                                <Option key={subcat} value={subcat}>
                                    {subcat}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                )}

                <Form.Item label="Imagen">
                    <Upload
                        listType="picture-card"
                        fileList={fileList}
                        onChange={handleFileChange}
                        beforeUpload={() => false}
                    >
                        {fileList.length < 1 && (
                            <div>
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>Subir</div>
                            </div>
                        )}
                    </Upload>
                </Form.Item>

                <Form.Item label="Descripción">
                    <Input.TextArea
                        rows={4}
                        onChange={handleInputChange}
                        name="description"
                        placeholder="Descripción del trabajo ganador"
                        value={inputTrabajo.description}
                    />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Guardar
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export { FormularioCrearTrabajoGanador };